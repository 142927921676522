import './WhatIdo.scss';

import SectionHeader from './../../generic/SectionHeader';
import { BackgroundType } from './../../../enums/GenericEnums';

import JobProfileGrid from './JobProfileGrid/index';
import { ProfileData } from '../../../models/Models';

interface WhatIdoProps {
    data: ProfileData;
}

const WhatIdo: React.FC<WhatIdoProps> = (props: WhatIdoProps) => {
    return (
        <section id="what-i-do" className="what-i-do">
            <div className="section-header-container">
                <SectionHeader 
                    title={props.data.sectionData.title} 
                    subtitle={props.data.sectionData.subtitle} 
                    paragraphs={props.data.sectionData.intro} 
                    background={BackgroundType.dark} />
            </div>
            <JobProfileGrid data={props.data.profiles} />
        </section>
    );
};

export default WhatIdo;