import './ProgressGrid.scss';

import ProgressBar from './ProgressBar';
import ProgressSectionTitle from './ProgressSectionTitle';
import { Skill, SkillSection } from '../../../../models/Models';

interface SkillsSectionProps {
    data: SkillSection[];
}

const ProgressGrid: React.FC<SkillsSectionProps> = (props: SkillsSectionProps) => {
    return (
        <div className="progress-grid" key="pg">            
            {props.data.map((skillSection: SkillSection, mainIndex) => (
                <div key={mainIndex}>
                    <div className="section-title-container">
                        <ProgressSectionTitle icon={skillSection.icon} title={skillSection.title} />
                    </div>                  
                    <div className="grid-container" key={`mgc-${mainIndex}`}>
                        {skillSection.skills.map((skill: Skill, index) => (
                            <div className="grid-item" key={`gi-${mainIndex}-${index}`}>
                                <ProgressBar skill={skill.name} percent={skill.value} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProgressGrid;