import './Home.scss';
import TypeWriter from 'typewriter-effect'
import ParticleComponent from './ParticlesComponent';

interface HomeProps {
    title: string;
    name: string;
    photoPath: string;
    profiles: string[];    
}

const Home: React.FC<HomeProps> = (props: HomeProps) => {
    return (
        <section id="home" className="home">       
            <ParticleComponent />
            <div className="content">
                <div className="photo">
                    <img src={props.photoPath} alt={props.name} />
                </div>
                <h1>{props.title}</h1>
                <h2>
                    <TypeWriter
                        options={{
                            strings: props.profiles,
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </h2>
            </div>
        </section>
    );
};

export default Home;