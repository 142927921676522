import './SocialMediaButton.scss';

import { SocialMediaType } from '../../../../enums/GenericEnums';

import { IconContext } from "react-icons";
import { SiLinkedin, SiInstagram } from 'react-icons/si'


interface SocialMediaButtonProps {
    type: number;
    name: string;
    url: string;    
};

const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({type, name, url}) => {

    const getTooltip = () => {
        return `Click to open ${name}`;
    };

    const onClick = () => {
        window.open(url, '_blank');
    };

    return (
        <div className="social-media-button" onClick={onClick} data-tooltip-id="av-tooltip" data-tooltip-content={getTooltip()}>    
            <IconContext.Provider value={{ className: "icon"}}>            
                {type === SocialMediaType.linkedin ? (
                    <SiLinkedin />
                ) : (
                    <SiInstagram />
                )}                
            </IconContext.Provider>   
        </div>
    );
};

export default SocialMediaButton;

