import './ExperienceGrid.scss';
import { Job } from '../../../../models/Models';
import ExperienceItem from './ExpirienceItem';

interface ExperienceGridProps {
    data: Job[]
}

const ExperienceGrid: React.FC<ExperienceGridProps> = (props: ExperienceGridProps) => {
    return (
        <div className="experience-grid">
            {props.data.map((job: Job, index) => (
                <ExperienceItem 
                    companyName={job.company} 
                    url={job.url}
                    jobTimeline={job.jobTimeline} 
                    dateFrom={job.start} 
                    dateUntil={job.end} 
                    key={index} />    
            ))}
        </div>
    );
};

export default ExperienceGrid;