import './Splash.scss';
import logo from './../../../assets/images/logos/logo-negative.svg';
import { useEffect, forwardRef, ForwardRefRenderFunction } from 'react';

interface SplashProps {
    timeout: number;
    onHideSplash: () => void;
}

const Splash: ForwardRefRenderFunction<HTMLDivElement, SplashProps> = (props, ref) => {
    useEffect(() => {
        console.log('start', new Date());
        const timer = setTimeout(() => {
            props.onHideSplash();
        }, props.timeout);
        return () => clearTimeout(timer);
    }, [props]);

    return (
        <div ref={ref} className="splash">
            <img src={logo} alt="av"/>
        </div>
    );
};

export default forwardRef(Splash);