import './MenuItem.scss';
import { BackgroundType } from '../../../../../enums/GenericEnums';

interface HeaderItemProps {
    itemId: string;
    url: string;
    anchor: string;
    text: string;
    selected: boolean;
    backgroundType: BackgroundType;
    onClick: (id: string, anchor: string) => void;
}

const HeaderItem: React.FC<HeaderItemProps> = (props: HeaderItemProps) => {

    const getClass = () => {
        let classname = "menu-item" + (props.selected ? " selected" : "");
        return classname + (props.backgroundType === BackgroundType.light ? " dark" : "");
    };

    const handleOnClick = () => {
        props.onClick(props.itemId, props.url);
    }

    return (
        <li className={getClass()} onClick={handleOnClick}>
            <span>{props.text}</span>
            <div id="underline" />
        </li>
    );
};

export default HeaderItem;