import './App.scss';

import { CSSTransition } from 'react-transition-group';
import MobileView from './components/layout/MobileView';
import DefaultView from './components/layout/DefaultView';
import Splash from './components/layout/Splash';

import { Tooltip } from 'react-tooltip'
import { useMediaQuery } from 'react-responsive';
import { useRef, useState, useEffect } from 'react';
import { CV } from './models/Models';

const TIMEOUT: number = 5000;

const App = () => {

  const [cv, setCV] = useState<CV | null>(null);
  const [shouldDisplaySplash, setShouldDisplaySplash] = useState(true);
  const splash = useRef(null);

  useEffect(() => {
    fetch('/cv/data.json')
      .then(response => {
        return response.json()
      })
      .then(data => {
        setCV(new CV(data));
      })
      .catch(error => console.error(error));
  }, []);

  const isMobile = useMediaQuery(
    { maxWidth: 768 }, undefined, undefined
  );

  const handleHideSplash = () => {
    setShouldDisplaySplash(false);
  };

  return (
    <>
      <div className="App">   
        { shouldDisplaySplash || !cv ? (
          <CSSTransition nodeRef={splash} in={shouldDisplaySplash} timeout={TIMEOUT} classNames="fade" unmountOnExit >
            <Splash ref={splash} timeout={TIMEOUT} onHideSplash={handleHideSplash} />
          </CSSTransition>
        ) : 
          isMobile ? (
            <MobileView data={cv} />
          ) : (
            <DefaultView data={cv} />
          )
        }          
      </div>
      <Tooltip id="av-tooltip" className="tooltip" place="bottom"/>
    </>
  );
}

export default App;
