
 export class HeaderItem {
    id: string;
    anchor: string;
    title: string;

    constructor (id: string, anchor: string, title: string) {
        this.id = id;
        this.anchor = anchor;
        this.title = title;        
    }
}

export class SectionData {
    title: string;
    subtitle: string;
    intro: string[];

    constructor (title: string, subtitle: string, intro: string[]) {
        this.title = title;
        this.subtitle = subtitle;
        this.intro = intro;
    }
}

export class Skill {
    name: string;
    value: number;

    constructor(name: string, percentage: number) {
        this.name = name;
        this.value = percentage;
    }
}

export class SkillSection {
    icon: string;
    title: string;
    skills: Skill[];

    constructor(icon: string, title: string, skills: Skill[]) {
        this.icon = icon;
        this.title = title;
        this.skills = skills;
    }
}

export class AboutData {
    sectionData: SectionData;
    skillSections: SkillSection[];

    constructor(aboutData: any) {
        this.sectionData = new SectionData(aboutData.title, aboutData.subtitle, aboutData.intro);
        this.skillSections = aboutData.skillsSections.map((ss: any) => {
            return new SkillSection(ss.icon, ss.title, ss.skills.map((skill: any) => {
                return new Skill(skill.name, skill.percentage);
            }))
        });
    }
}

export class Profile {
    name: string;
    text: string[];
    icon: string;

    constructor(name: string, text: string[], icon: string) {
        this.name = name;
        this.text = text;
        this.icon = icon;
    }
}

export class ProfileData {
    sectionData: SectionData;
    profiles: Profile[];

    constructor(data: any) {
        this.sectionData = new SectionData(data.title, data.subtitle, data.intro);
        this.profiles = data.list.map((profile: any) => {
            return new Profile(profile.name, profile.text, profile.icon);
        });
    }

    getProfileNames(): string[] {
        return this.profiles.map((p: Profile) => {
            return p.name;
        });
    }
}


export class JobTimeline {
    start: Date;
    end: Date;
    title: string;
    description: string[];

    constructor(start: Date, end: Date, title: string, description: string[]) {
        this.start = start;
        this.end = end;
        this.title = title;
        this.description = description;
    } 
}

export class Job {
    start: Date;
    end: Date;
    company: string;
    url: string;
    jobTimeline: JobTimeline[];

    constructor(start: Date, end: Date, company: string, url: string, timeline: any[]) {
        this.start = start;
        this.end = end;
        this.company = company;
        this.url = url;
        this.jobTimeline = timeline.map((jt: any) => {
            return new JobTimeline(jt.start, jt.end, jt.title, jt.description);
        });
    }
}

export class WorkData {
    sectionData: SectionData;
    workHistory: Job[];

    constructor(data: any) {
        this.sectionData = new SectionData(data.title, data.subtitle, data.intro);
        this.workHistory = data.list.map((job: any) => {
            return new Job(job.start, job.end, job.company, job.url, job.timeline);
        });
    }
}

export class SocialMedia {
    name: string;
    url: string;
    iconType: number;

    constructor(name: string, url: string, iconType: number) {
        this.name = name;
        this.url = url;
        this.iconType = iconType;
    }
}

export class ContactData {
    sectionData: SectionData;
    socialMedia: SocialMedia[];

    constructor(data: any) {
        this.sectionData = new SectionData(data.title, data.subtitle, data.intro);
        this.socialMedia = data.list.map((sm: any) => {
            return new SocialMedia(sm.name, sm.url, sm.iconType);
        });
    }
}

export class CV {
    name: string;
    title: string;
    photoPath: string;
    aboutData: AboutData;
    profileData: ProfileData;
    workData: WorkData;
    contactData: ContactData;

    constructor(cv: any) {
        this.title = cv.title;
        this.name = cv.name;
        this.photoPath = cv.photoPath;
        this.aboutData = new AboutData(cv.about);
        this.profileData = new ProfileData(cv.profiles);
        this.workData = new WorkData(cv.work);
        this.contactData = new ContactData(cv.contact);
    }
}