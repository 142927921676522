import './Footer.scss';

import logo from '../../../assets/images/logos/logo-negative.svg';


const Footer = () => {
    return (
        <div className="footer">
            <img src={logo} alt="Alex Villamizar"/>
            <span>Designed and Developed by Alex Villamizar</span>
            <span>2023</span>
        </div>
    );
};

export default Footer;