import './Contact.scss';

import SectionHeader from '../../generic/SectionHeader';
import SocialMediaButton from './SocialMediaButton';
import { BackgroundType } from '../../../enums/GenericEnums';
import { ContactData, SocialMedia } from '../../../models/Models';

interface ContactProps {
    data: ContactData;
}

const Contact: React.FC<ContactProps> = ({ data }) => {
    return (
        <section id="contact" className="contact">
            <div className="header-container">
                <SectionHeader 
                    title={data.sectionData.title} 
                    subtitle={data.sectionData.subtitle} 
                    paragraphs={data.sectionData.intro} 
                    background={BackgroundType.dark} />
            </div>
            <div className="social-media">
                {data.socialMedia.map((sm: SocialMedia, index: number) => (
                    <SocialMediaButton type={sm.iconType} name={sm.name} url={sm.url} key={index}/>    
                ))}
            </div>
        </section>        
    );
};

export default Contact;