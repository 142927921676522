import { useState, useEffect } from 'react';
import MenuItem from './MenuItem';
import { BackgroundType } from '../../../../enums/GenericEnums';
import {HeaderItem } from '../../../../models/Models';

import './Header.scss';
import logo from './../../../../assets/images/logos/logo-positive.svg';

const headerMenuItemsArr: HeaderItem[] = [
    new HeaderItem('mi-home', 'home', 'Home'),
    new HeaderItem('mi-aboutme', 'about-me', 'About me'),
    new HeaderItem('mi-whatido', 'what-i-do', 'What I do'),
    new HeaderItem('mi-work', 'work', 'Work'),
    new HeaderItem('mi-contact', 'contact', 'Contact')
];

const Header = () => {

    const [classStyle, setClassStyle] = useState('default-header');
    const [backgroundType, setBackgroundType] = useState(BackgroundType.dark);
    const [selectedId, setSelectedId] = useState("mi-home");

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollPos = window.scrollY;
        const sections = document.querySelectorAll('section');
        const header = document.querySelector('header');

        sections.forEach((section) => {
            const sectionTop = section.offsetTop - (header != null ? header.offsetHeight : 0);
            const sectionBottom = sectionTop + section.offsetHeight;          

            
            if (scrollPos >= sectionTop && scrollPos < sectionBottom) {                
                let selItem = headerMenuItemsArr.find(i => i.anchor === section.id);
                if ((window.innerHeight +  Math.round(window.pageYOffset) + (header != null ? header.offsetHeight : 0)) >= document.body.scrollHeight) {
                    selItem = headerMenuItemsArr[headerMenuItemsArr.length-1];
                }

                if (selItem != null) {
                    setSelectedId(selItem.id);
                }
            }
        });

        
        if (scrollPos > 0) {
            setClassStyle('default-header header-visible');
            setBackgroundType(BackgroundType.light);
        }
        else {
            setClassStyle('default-header');
            setBackgroundType(BackgroundType.dark);
        }
    };
    
    const onMenuItemClick = (id: string, anchor: string) => {
        setSelectedId(id);
        const anchorElement = document.querySelector(`${anchor}`);
        if (anchorElement != null ) {
            anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const isMenuSelected = (id: string): boolean => {
        return (selectedId === id || (selectedId === "" && id === "mi-home"));
    };

    return (
        <header className={classStyle}>            
            <img className="logo" src={logo} alt="Alex Villamizar" />
            <nav>
                <ul>
                    { headerMenuItemsArr.map((item, index) => (
                        <MenuItem
                            key={index}
                            itemId={item.id}
                            anchor={item.anchor}
                            url={`#${item.anchor}`}
                            text={item.title}
                            selected={isMenuSelected(item.id)}
                            backgroundType={backgroundType}
                            onClick={onMenuItemClick} />
                    ))}
                </ul>
            </nav>
        </header>
    );
};

export default Header;