import './ProgressSectionTitle.scss';

const ICON_PATH = '/icons/';

interface ProgressSectionTitleProps {
    icon: string;
    title: string;
}

const ProgressSectionTitle: React.FC<ProgressSectionTitleProps> = (progressSectionTitleProps) => {

    const iconPath = `${ICON_PATH}${progressSectionTitleProps.icon}.svg`;

    return (
        <div className="section-title">
            <img src={iconPath} alt={progressSectionTitleProps.icon}></img>
            <span>{progressSectionTitleProps.title}</span>
        </div>
    );
};

export default ProgressSectionTitle;