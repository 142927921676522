export enum BackgroundType {
    light, 
    dark
}

export enum SocialMediaType {
    instagram,
    linkedin
}


export enum TabItemType {
    home,
    aboutMe,
    whatIDo,
    work,
    contact
}