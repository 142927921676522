import './AboutMe.scss';

import ProgressGrid from './ProgressGrid';
import { BackgroundType } from './../../../enums/GenericEnums';
import SectionHeader from './../../generic/SectionHeader';
import { AboutData } from '../../../models/Models';

interface AboutMeProps {
    data: AboutData;
}

const AboutMe: React.FC<AboutMeProps> = (props: AboutMeProps) => {
    return (
        <section id="about-me" className="about-me">            
            <div className="section-header-container">
                <SectionHeader 
                    title={props.data.sectionData.title} 
                    subtitle={props.data.sectionData.subtitle} 
                    paragraphs={props.data.sectionData.intro} 
                    background={BackgroundType.light} />
            </div>
            <ProgressGrid data={props.data.skillSections} />            
        </section>
    );
};

export default AboutMe;