import './SectionHeader.scss';
import { BackgroundType } from './.././../../enums/GenericEnums';

interface SectionTitleProps {
    title: string;
    subtitle: string;
    paragraphs?: string[] | [];
    background: BackgroundType; 
}

const SectionHeader: React.FC<SectionTitleProps> = ({ title, subtitle, paragraphs, background}) => {

    const textStyle = background === BackgroundType.dark ? "light" : "dark";

    return (
        <div className="section-header">
            <p className="section-name">{title}</p>
            <p className={"callout " + textStyle}>{subtitle}</p>
            <div className={"description-container " + textStyle}>
                { paragraphs && paragraphs.map((paragraph, index) => (
                    <p key={index} className="description">{paragraph}</p>
                ))}
            </div>
        </div>
    );
};

export default SectionHeader;