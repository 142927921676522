import './Work.scss';

import { BackgroundType } from '../../../enums/GenericEnums';

import SectionHeader from '../../../components/generic/SectionHeader';
import ExperienceGrid from './ExperienceGrid';
import { WorkData } from '../../../models/Models';

interface WorkProps {
    data: WorkData;
}

const Work: React.FC<WorkProps> = (props: WorkProps) => {
    return (
        <section id="work" className="work">
            <div className="section-container">
                <SectionHeader 
                    title={props.data.sectionData.title} 
                    subtitle={props.data.sectionData.subtitle} 
                    background={BackgroundType.light} />
            </div>
            <ExperienceGrid data={props.data.workHistory} />
        </section>
    );
};

export default Work;