import './MobileView.scss';

import Home from '../../sections/home';
import AboutMe from '../../sections/about-me';
import WhatIdo from '../../sections/what-i-do';
import Work from '../../sections/work';
import Contact from '../../sections/contact';
import Footer from '../../generic/footer';
import MobileTabBar from './MobileTabBar';
import { ReactNode, useState } from 'react';
import { CV } from '../../../models/Models';

interface MobileViewProps {
  data: CV;
}


const MobileView: React.FC<MobileViewProps> = (props: MobileViewProps) => {
    const [selectedView, setSelectedView] = useState('');
 
    const onClick = (viewId: string) => {
      setSelectedView(viewId);
      window.scrollTo(0, 0);
    }

    const getMobileViewToDisplay = (): ReactNode => {
        if (selectedView === 'about-me') {
          return <AboutMe data={props.data.aboutData} />
        }
        else if (selectedView === 'what-i-do') {
          return <WhatIdo data={props.data.profileData} />
        }
        else if (selectedView === 'work') {
          return <Work data={props.data.workData} />
        }
        else if (selectedView === 'contact') {
          return <Contact data={props.data.contactData}  />
        }
        else {
          return <Home 
                    title={props.data.title} 
                    name={props.data.name} 
                    profiles={props.data.profileData.getProfileNames()} 
                    photoPath={props.data.photoPath} />
        }
      };

    const isMainView = ():boolean => {
        return selectedView === 'home' || selectedView === '';
    };

    return (
        <div className="mobile-container">            
            { isMainView() ? (
                getMobileViewToDisplay()
            ) : (
                <div className="sub-container">
                    {getMobileViewToDisplay()}
                    <Footer />
                </div>
            )}
            <MobileTabBar onClick={onClick} />
        </div>
    );
};

export default MobileView;