import './TabItem.scss';

import { ReactNode } from 'react';


interface TabItemProps { 
    viewIdToDisplay: string;   
    selectedViewIdToDisplay: string;
    text: string;    
    children: ReactNode;
    onClick:  (viewIdToDisplay: string) => void;
};

const TabItem: React.FC<TabItemProps> = (props: TabItemProps) => {    

    const ontabItemClick = () => {
        //setIsSelected(true);
        props.onClick(props.viewIdToDisplay);
    };

    const isSelected = () => {
        return  props.selectedViewIdToDisplay === props.viewIdToDisplay;
    }

    const getStyleClass = () => {
        return "tab-item" + (isSelected() ? " tab-sel" : "");
    };

    return (
        <div className={getStyleClass()} onClick={ontabItemClick}>
            {props.children}
            <span>{props.text}</span>
        </div>
    );
};

export default TabItem;
