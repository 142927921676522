import './ExperienceItem.scss';

import moment from 'moment';
import { JobTimeline } from '../../../../../models/Models';


import { IconContext } from "react-icons";
import { BsLink45Deg } from 'react-icons/bs'

interface ExperienceItemProps {
    dateFrom: Date;
    dateUntil?: Date | null;
    companyName: string;
    url?: string | null;
    jobTimeline: JobTimeline[];
};

const ExperienceItem: React.FC<ExperienceItemProps> = ({dateFrom, dateUntil, companyName, jobTimeline, url}) => {

    const getDurationText = (start: Date, end?: Date | null) => {
        let duration = start == null ? "-" : (moment(start)).format('MMM YYYY');
        duration += end == null ? " - Current" : ` - ${(moment(end)).format('MMM YYYY')}`;
        return duration;
    };

    const getCompanyNameClassName = () => {
        return url ? "url-style" : "no-url";
    };

    const openCompanySite = () => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <div className="experience-item">
            <div className="duration">
                <span>{getDurationText(dateFrom, dateUntil)}</span>
            </div>
            <div className="content">
                <span className={getCompanyNameClassName()} onClick={openCompanySite}>
                    {companyName}
                    {url && (
                        <IconContext.Provider value={{ className: "link-icon"}}>            
                            <BsLink45Deg />       
                        </IconContext.Provider>  
                    )} 
                </span>
                {jobTimeline.map((jt: JobTimeline, index: number) => (
                    <div className="job-timeline" key={index}>
                        <>
                            <span>- {jt.title} ({getDurationText(jt.start, jt.end)}):</span>
                            {jt.description.map((text, i) => {
                                return (<p key={i}>{text}</p>);
                            })}
                        </>                        
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExperienceItem;