import './ProgressBar.scss';

interface ProgressBarProps {
    skill: string;
    percent: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({skill, percent}) => {

    const percentString = `${percent}%`;

    const progressStyle : React.CSSProperties = {
        width: percentString
    };

    return (
        <div className="progress-bar">
            <div className="skill">
                <span className="description">{skill}</span>
                <span className="percentage">{percentString}</span>
            </div>
            <div className="bar-container">
                <div className="progress" style={progressStyle}></div>
            </div>
        </div>
    );
};

export default ProgressBar;