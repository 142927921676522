import './JobProfileCard.scss';
import Icon from '@mdi/react';
import { mdiAccountClock, mdiLightbulbOutline, mdiXml, mdiSquareRoundedOutline  } from '@mdi/js';

interface JobProfileCardProps {
    icon: string;
    title: string;
    text: string[];
}

const JobProfileCard: React.FC<JobProfileCardProps> = ({icon, title, text}) => {

    const getIcon = () => {
        if (icon === "project-manager") {
            return mdiAccountClock;
        }
        else if (icon === "developer") {
            return mdiXml;
        }
        else if (icon === "designer") {
            return mdiLightbulbOutline;
        }
        else {
            return mdiSquareRoundedOutline;
        }
    }

    return (
        <div className="job-profile-card">
            <div className="container">
                <Icon path={getIcon()} className="icon"/>
                <span>{title}</span>
                {text.map((t, index) => (
                    <p key={index}>{t}</p>
                ))}
            </div>
        </div>
    );
};

export default JobProfileCard;