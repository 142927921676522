import './MobileTabBar.scss';
import logo from '../../../../assets/images/logos/simple-logo-light.svg';
import { TabItemType } from '../../../../enums/GenericEnums';
import TabItem from './TabItem';

import { ReactNode, useState } from 'react';
import { BsPersonBoundingBox, BsEnvelopeFill } from 'react-icons/bs';
import { IoIosListBox } from 'react-icons/io';
import { GiSkills } from 'react-icons/gi';
import { IconContext } from 'react-icons';


interface MobileTabBarProps {
    onClick: (viewId: string) => void;
}

const MobileTabBar: React.FC<MobileTabBarProps> = (props: MobileTabBarProps) => {
    const [selectedTabItem, setSelectedTabItem] = useState('home');
    const onTabItemClick = (viewIdToDisplay: string) => {
        setSelectedTabItem(viewIdToDisplay);
        props.onClick(viewIdToDisplay);
    };

    const onMainTabItemClick = () => {
        setSelectedTabItem('home');
        props.onClick('home');
    };


    const getIcon = (tabItemType: TabItemType): ReactNode => {
        if (tabItemType === TabItemType.aboutMe) {
            return <BsPersonBoundingBox />;
        }
        else if (tabItemType === TabItemType.whatIDo) {
            return <GiSkills />;
        }
        else if (tabItemType === TabItemType.work) {
            return <IoIosListBox />;
        }
        else if (tabItemType === TabItemType.contact) {
            return <BsEnvelopeFill />;
        }
        else {
            return <img src={logo} alt="av"/>
        }
    };

    const generateIconCode = (tabItemType: TabItemType): ReactNode => {
        return (
            <IconContext.Provider value={{ className: "icon"}}>            
                {getIcon(tabItemType) }            
            </IconContext.Provider>   
        );
    };

    const getMainTabStyle = () => {
        return "main-tab-item" + (selectedTabItem === "home" ? " main-sel" : "");
    };

    return (
        <div className="tab-bar">
            <div className="inner-tab">
                <div className="tab-items left">
                    <TabItem text="About me" viewIdToDisplay="about-me" selectedViewIdToDisplay={selectedTabItem} onClick={onTabItemClick}>
                        {generateIconCode(TabItemType.aboutMe)}
                    </TabItem>
                    <TabItem text="What I do" viewIdToDisplay="what-i-do" selectedViewIdToDisplay={selectedTabItem} onClick={onTabItemClick}>
                        {generateIconCode(TabItemType.whatIDo)}
                    </TabItem>
                </div>
                <div className="tab-items right">
                    <TabItem text="Work" viewIdToDisplay="work" selectedViewIdToDisplay={selectedTabItem} onClick={onTabItemClick}>
                        {generateIconCode(TabItemType.work)}
                    </TabItem>
                    <TabItem text="Contact" viewIdToDisplay="contact" selectedViewIdToDisplay={selectedTabItem} onClick={onTabItemClick}>
                        {generateIconCode(TabItemType.contact)}
                    </TabItem>
                </div>
            </div>
            <div className={getMainTabStyle()} onClick={onMainTabItemClick}>
                {getIcon(TabItemType.home)}
            </div>
        </div>
    );
};

export default MobileTabBar;