import './JobProfileGrid.scss';
import JobProfileCard from './JobProfileCard';
import { Profile } from '../../../../models/Models';

interface JobProfileGridProps {
    data: Profile[];
}

const JobProfileGrid: React.FC<JobProfileGridProps> = (props: JobProfileGridProps) => {
    return (
        <div className="job-profile-grid">
            {props.data.map((profile: Profile, index) => (                
                <JobProfileCard title={profile.name} icon={profile.icon} text={profile.text} key={index} />    
            ))}
        </div>
    );
};

export default JobProfileGrid;

