import './DefaultView.scss';

import Header from '../../layout/DefaultView/header';
import Home from '../../sections/home';
import AboutMe from '../../sections/about-me';
import WhatIdo from '../..//sections/what-i-do';
import Work from '../..//sections/work';
import Contact from '../..//sections/contact';
import Footer from '../../generic/footer';
import { CV } from '../../../models/Models';

interface DefaultViewProps {
    data: CV;
}

const DefaultView: React.FC<DefaultViewProps> = (props: DefaultViewProps) => {
    return (
        <>
            <Header/>
            <div className="main-container">
                <Home 
                    title={props.data.title} 
                    name={props.data.name} 
                    profiles={props.data.profileData.getProfileNames()} 
                    photoPath={props.data.photoPath} />  
                <AboutMe data={props.data.aboutData} />
                <WhatIdo data={props.data.profileData} />
                <Work data={props.data.workData} />
                <Contact data={props.data.contactData} />
                <Footer />
            </div>
        </>
    );
};

export default DefaultView;